import {
  getCampaignTemplates,
  getTargets,
  addTemplate,
  throwError,
  addStage,
  customizeMessage,
  addOrUpdateReview,
  getActiveDashboard,
  getCompleteDashboard,
  getPrivateFeedback,
  setLoaderState,
  showSidebar
} from "./types";

const initialStores = {
  campaignTemplates: [],
  targets: [],
  activeDashboardData: [],
  completeDashboardData: [],
  feedbackData: [],
  error: null,
  loader: false,
  success: false,
  showSidebar: false,
};

const settingsReducer = (state = initialStores, action) => {
  switch (action.type) {
    case getCampaignTemplates:
      return {
        ...state,
        error: null,
        campaignTemplates: action.payload,
      };
    case getActiveDashboard:
      return {
        ...state,
        error: null,
        activeDashboardData: action.payload,
      };
    case getCompleteDashboard:
      return {
        ...state,
        error: null,
        completeDashboardData: action.payload,
      };
    case getPrivateFeedback:
      return {
        ...state,
        error: null,
        feedbackData: action.payload,
      };

    case getTargets:
      return {
        ...state,
        error: null,
        targets: action.payload,
        loader: false
      };
    case addOrUpdateReview:
      return {
        ...state,
        error: null,
        ...action.payload
      }
    case setLoaderState:
      return {
        ...state,
        loader: action.payload
      }
    case showSidebar:
      return {
        ...state,
        showSidebar: action.payload
      }
    case addTemplate:
      return {
        ...state,
        error: null,
        campaignTemplates: [...state.campaignTemplates, action.payload],
      };
    case addStage:
      const newTemplates = state.campaignTemplates.map((t) =>
        t.campaignTemplateId !== action.payload.templateId
          ? t
          : {
            ...t,
            messages: action.payload.messages,
          }
      );

      return {
        ...state,
        error: null,
        campaignTemplates: newTemplates,
      };
    case customizeMessage:
      const newTemplates2 = state.campaignTemplates.map((t) => {
        if (t.campaignTemplateId !== action.payload.templateId) {
          return t;
        } else {
          let newMessages = t.messages.map((m) => {
            if (m.messageTemplate !== action.payload.messageTemplate) {
              return m;
            } else {
              return {
                day: action.payload.messageDay,
                type: action.payload.messageType,
                messageTemplate: action.payload.messageTemplate,
                subject: action.payload.subject,
                message: action.payload.message,
              };
            }
          });
          return {
            ...t,
            messages: newMessages,
          };
        }
      });

      return {
        ...state,
        error: null,
        campaignTemplates: newTemplates2,
      };
    case throwError:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;