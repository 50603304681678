import React, {Suspense, lazy} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignIn from "../../pages/LoginScreen/SignIn";
import Spinner from '../common/spinner/Spinner.js';
import {AppRoutes} from './AppRoutes.js';
const SignUp = lazy(() => import('../../pages/LoginScreen/SignUp'));
const ForgotPassword = lazy(() => import('../../pages/LoginScreen/ForgotPassword'));
const ResetPassword = lazy(() => import('../../pages/LoginScreen/ResetPassword'));

export const Navigation = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner/>}>
        <Switch>
          {/* navigate to the signup or sign in page based on their corresponding route */}
          <Route path="/signup" exact component={SignUp} />
          <Route path="/signin" exact component={SignIn} />
          <Route path="/forgot" exact component={ForgotPassword} />
          <Route path="/reset" exact component={ResetPassword} />
          {/* contains info about side bar navigation option */}
          <AppRoutes />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
