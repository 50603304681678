import { Redirect } from "react-router";
import  {ReactComponent as Spinner } from "../assets/svg/spinner.svg";

export const redirectToPath = (isPathSet, path) => {
  if (isPathSet) {
    return <Redirect to={path} />;
  }
};

export const InputField = ({ label, placeholder, onChange, value, name, type, disabled }) => {
  return (
    <div className="input-container">
      <span>{label}</span>
      <input
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        disabled = {disabled}
        required
      />
    </div>
  );
};

export const Button = ({buttonText,  loggingInState }) => {
  return (
    <button className={`button${loggingInState ? " button-loading" : ""}`}>
       {loggingInState && <Spinner/>} {buttonText}
    </button>
  );
};

