import { ChangeSelectedStore } from "./types";

const initialStores = "";

const selectedStoreReducer = (state = initialStores, action) => {
  switch (action.type) {
    case ChangeSelectedStore:
      return action.payload;
    default:
      return state;
  }
};

export default selectedStoreReducer;
