import { ChangeStores, AppendStores, AddReviewLinks } from "./types";

export const CHANGE_STORES = (data) => {
  return {
    type: ChangeStores,
    payload: data,
  };
};
export const CLEAR_STORES = () => {
  return {
    type: ChangeStores,
    payload: [],
  };
};

export const APPEND_STORES = (data) => {
  return {
    type: AppendStores,
    payload: data,
  };
};

export const ADD_REVIEWLINKS = (data) => {
  return {
    type: AddReviewLinks,
    payload: data,
  };
};