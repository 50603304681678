import React from "react";
import "./App.scss";
import { Navigation } from "./components/navigation/Navigation";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Navigation />
      <ToastContainer position="bottom-left"/>
    </>
  )
}

export default App;