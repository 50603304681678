import api from "./api";

const getReviewLinks = (selectedStore) => {
  return api.get(`/reviewlinks/getreviewlinks?propertyid=${selectedStore}`);
};

const getPropertyNames = () => {
  return api.get("/properties/getpropertynames");
};

const addProperty = (propertyName,
    propertyType,
    streetAddress,
    city,
    state,
    country,
    pinCode,
    propertyPhone,
    propertyEmail,
    propertyLogo,
    googlePlaceId,
    previewSource) => {
  return api.post("/properties/addproperty",
  {
    propertyName,
    propertyType,
    streetAddress,
    city,
    state,
    country,
    pinCode,
    propertyPhone,
    propertyEmail,
    propertyLogo,
    googlePlaceId,
    previewSource
  })
};

const updateProperty = (propertyId,
  propertyName,
  propertyType,
  streetAddress,
  city,
  state,
  country,
  pinCode,
  propertyPhone,
  propertyEmail,
  propertyLogo,
  googlePlaceId,
  previewSource) => {
return api.post("/properties/updateproperty",
{
  propertyId,
  propertyName,
  propertyType,
  streetAddress,
  city,
  state,
  country,
  pinCode,
  propertyPhone,
  propertyEmail,
  propertyLogo,
  googlePlaceId,
  previewSource
})
};

const getPropertyInfoById = (selectedStore ) => {
  return api.get(`/properties/getpropertyinfobyid?propertyId=${selectedStore}`);
};

const addUpdateReviewLinks = (selectedStore, reviewLinks ) => {
  return api.post(
    "/reviewlinks/addupdatereviewlinks",
    {
      propertyid: selectedStore,
      reviewlinks: reviewLinks
    });
};

const getCampaignTemplates = (selectedStore ) => {
  return api.get(`/campaignconfiguration/getCampaignTemplates?propertyid=${selectedStore}`);
};

const addNewTemplate = (propertyId, templateName ) => {
  return api.post(
   "/campaignconfiguration/addNewTemplate",
   {
    propertyId,
    templateName,
  });
};

const addNewStage = (propertyId,
  campaignTemplateId,
  messageType,
  messageDay,
  subject,
  message) => {
  return api.post(
   "/campaignconfiguration/addNewStage",
   {
    propertyId,
    campaignTemplateId,
    messageType,
    messageDay,
    subject,
    message
  });
};

const customisemsg = (customizeData) => {
  return api.post(
   "/campaignconfiguration/customisemsg",
   customizeData
   );
};

const manualstart = (newCampaign) => {
  return api.post(
   "/startcampaign/manualstart",
   newCampaign
   );
};

const getActiveDashboard = (propertyId) => {
  return api.get(
   `/campaigndashboard/getActiveDashboard?propertyId=${propertyId}`
   );
};

const getActiveDashboardQuery = (propertyId,searchData) => {
  return api.get(
     `/campaigndashboard/getActiveDashboardQuery?propertyId=${propertyId}&filter=${searchData}`  
   );
};

const getCompleteDashboard = (propertyId) => {
  return api.get(
    `/campaigndashboard/getCompleteDashboard?propertyId=${propertyId}`
   );
};

const getCompleteDashboardQuery = (propertyId,searchData) => {
  return api.get(
    `/campaigndashboard/getCompleteDashboardQuery?propertyId=${propertyId}&filter=${searchData}`
  );
};

const getPrivateFeedback = (propertyId) => {
  return api.get(
    `/campaigndashboard/getPrivateFeedback?propertyId=${propertyId}`
  );
};

const getPrivateFeedbackQuery = (propertyId,searchData) => {
  return api.get(
    `/campaigndashboard/getPrivateFeedbackQuery?propertyId=${propertyId}&filter=${searchData}`
  );
};

const getDetails = (propertyId,campaignId,customerId) => {
  return api.get(
    `/campaigndashboard/getDetails?propertyId=${propertyId}&campaignId=${campaignId}&customerId=${customerId}`
  );
};

const stopCampaign = (propertyId,campaignId,customerId) => {
  return api.get(
    `/campaigndashboard/stopcampaign?propertyId=${propertyId}&campaignId=${campaignId}&customerId=${customerId}`
  );
};

const UserService = {
  getReviewLinks,
  addProperty,
  updateProperty,
  getPropertyInfoById,
  getPropertyNames,
  addUpdateReviewLinks,
  getCampaignTemplates,
  addNewTemplate,
  addNewStage,
  customisemsg,
  manualstart,
  getActiveDashboard,
  getActiveDashboardQuery,
  getCompleteDashboard,
  getCompleteDashboardQuery,
  getPrivateFeedback,
  getPrivateFeedbackQuery,
  getDetails,
  stopCampaign
};

export default UserService;