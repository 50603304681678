import React, { lazy,Suspense } from "react";
import Spinner from '../common/spinner/Spinner.js';
import { Redirect, Route } from "react-router-dom";
import { sideNavigation } from "../../helpers/navigationOptions";
import { STORAGE_KEY } from "../../helpers/variables";
import Page from "../Page";
const AddStorePage = lazy(() => import('../../pages/Home/AddStorePage'));
const AddStoreForm = lazy(() => import('../../pages/Home/components/AddStoreForm'));
const Dashboard = lazy(() => import("../../pages/Dashboard/Dashboard"));

export const AppRoutes = () => {
  const user = localStorage.getItem(STORAGE_KEY);
  return (
    user ?
      (
        <Page>
          <Suspense fallback={<Spinner />}>

            {/* contains info about side bar navigation option */}
            {sideNavigation.map((item, key) =>
              item.subMenu ? (
                item.subMenu.map((sub, i) => (
                  <Route path={sub.path} exact component={sub.component} key={i} />
                ))
              ) : (
                <Route path={item.path} exact component={item.component} key={key} />
              )
            )}
            <Route path="/addstorepage" exact component={AddStorePage} />
            <Route path="/home/form" exact component={AddStoreForm} />
            <Route path="/" exact component={Dashboard} />
          </Suspense>
        </Page>
      ) : (
        <Redirect to={"/signin"} />
      )
  )
};
