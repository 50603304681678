import axios from "axios";
import {API_URL } from "../helpers/variables";
import TokenService from "./token.service";
import AuthService from "./auth.service";

const instance = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
       config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/users/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const res = await instance.post("/users/getVerifiedToken", {
            email: TokenService.getUser()?.email,
            authToken: TokenService.getLocalAccessToken(),
            refreshtoken: TokenService.getRefreshToken(),
          });
          const { token } = res.data.authToken;
          TokenService.updateLocalAccessToken(token);
          return instance(originalConfig);
        } catch (_error) {
          AuthService.logout();          
          window.location.href = '/signin';
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;