export const getCampaignTemplates = "getCampaignTemplates";
export const addTemplate = "addTemplate";
export const addStage = "addStage";
export const customizeMessage = "customizeMessage";
export const startCampaign = "startCampaign";
export const getTargets = "getTargets";
export const throwError = "throwError";
export const addOrUpdateReview = "addOrUpdateReview";
export const getActiveDashboard = "getActiveDashboard";
export const getCompleteDashboard = "getCompleteDashboard";
export const getPrivateFeedback = "getPrivateFeedback";
export const getDetails = "getDetails";
export const stopCampaign = "stopCampaign";
export const setLoaderState = "setLoaderState";
export const showSidebar = "showSidebar";