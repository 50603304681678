import { createStore, combineReducers, applyMiddleware } from "redux";
import selectedStoreReducer from "./selectedStore/reducer";
import settings from "./settings/reducer";
import storesReducer from "./stores/reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";

const allReducers = combineReducers({
  stores: storesReducer,
  selectedStore: selectedStoreReducer,
  settings: settings,
});

const store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(logger, thunk))
);

export default store;
