import { lazy } from "react";
import grid from "../assets/grid.png";
import myReview from "../assets/my-review.png";
import dashboard from "../assets/dashboard.png";
import integration from "../assets/integration.png";
import setting from "../assets/setting.png";
import reviewLink from "../assets/review-link.png";
import campaignSetting from "../assets/compaign-setting.png";
import location from "../assets/location.png";

const AddStorePage = lazy(() => import('../pages/Home/AddStorePage'));
const ReviewLinks = lazy(() => import('../pages/Reviews/ReviewLinks'));
const CreateCampaign = lazy(() => import("../pages/CreateCampaign/index"));
const CampaignSetting = lazy(() => import("../pages/CampaignSetting/index"));
const LocationSetting = lazy(() => import("../pages/LocationSetting/index"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));

export const sideNavigation = [
  {
    title: "Create Campaign",
    logo: grid,
    path: "/createcampaign",
    component: CreateCampaign,
  },
  {
    title: "Dashboard",
    logo: dashboard,
    path: "/dashboard",
    component: Dashboard,
  },
  {
    title: "Settings",
    logo: setting,
    path: "/my-review",
    component: AddStorePage,
    subMenu: [
      {
        title: "Review Links",
        logo: reviewLink,
        path: "/reviewlinks",
        component: ReviewLinks,
      },
      {
        title: "Campaign Settings",
        logo: campaignSetting,
        path: "/campaignsetting",
        component: CampaignSetting,
      },
      {
        title: "Location Settings",
        logo: location,
        path: "/locationsetting",
        component: LocationSetting,
      }
    ]
  },
];
