import './LoginScreen.scss';
import React, { useState } from "react";
import { Redirect, AbsoluteRedirect } from "react-router";
import crisp from "../../assets/crisp.png";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import { InputField, Button } from "../../helpers/helpersFunctions";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_STORES } from "../../redux/stores/actions";
import { CHANGE_SELECTED_STORES } from "../../redux/selectedStore/actions";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [redirectToSignUp, setRedirectToSignUp] = useState(false);
  const [loggingInState, setLoggedInState] = useState(false);
  const [storesPresent, setStoresPresent] = useState(false);

  //Getting state from redux
  const globalState = useSelector((state) => state);
  const { selectedStore } = globalState;
  const dispatch = useDispatch();
  const onChange = (e) => {
    switch (e.target.name) {
      case "email":
        return setEmail(e.target.value);
      case "password":
        return setPassword(e.target.value);

      default:
        break;
    }
  };

  const getStores = () => {
    UserService.getPropertyNames().then((res) => {
      console.log("data properties: " + res.data.properties);
      //saving it in global state to access anywhere in app.
      dispatch(CHANGE_STORES(res.data.properties));

      if (res.data.properties?.length) {
        setStoresPresent(true);
        if (!selectedStore) {
          dispatch(
            CHANGE_SELECTED_STORES(
              res.data.properties && res.data.properties[0].propertyid
            )
          );
        }
      }

      setLoggedInState(false);
      setRedirectToHome(true);
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoggedInState(true);
    AuthService.login(email, password).then((response) => {
      if (response) {
        if(response.plan === "trial" && response.trial_expires == 0){
          window.location = "https://www.test.com?&email=" + email;
        } else {
          getStores();
        }
      }
    }).catch((err) => {
      if (err.response) alert(err.response.data.message);
      setLoggedInState(false);
    });
  };

  const redirectMeToHome = () => {
    if (redirectToHome) {
      if (storesPresent) {
        return <Redirect to="/createcampaign" />;
      }
      return <Redirect to="/addstorepage" />
    }
  };

  const redirectMeToSignUp = () => {
    if (redirectToSignUp) {
      return <Redirect to="/signup" />;
    }
  };

  return (
    <div className="home registration">
      {redirectMeToHome()}
      {redirectMeToSignUp()}
      <div className="registration__middleTransparentContainer">
        <a href="\" className="registration__middleTransparentContainer__upper">
          <img src={crisp} alt="website logo icon" />
          <h4>Reviewra</h4>
        </a>
        <div className="sign-up-image">

          <img src="https://assets.website-files.com/61e3aa555a9149f4f1e68fe4/61e3aa555a91496b66e690ae_Left-min.png" loading="lazy" sizes="(max-width: 991px) 100vw, (max-width: 1919px) 40vw, 720px" srcSet="https://assets.website-files.com/61e3aa555a9149f4f1e68fe4/61e3aa555a91496b66e690ae_Left-min-p-500.png 500w, https://assets.website-files.com/61e3aa555a9149f4f1e68fe4/61e3aa555a91496b66e690ae_Left-min.png 600w" alt="" className="image">
          </img>
          <div className="testimonial-item sign-up-page">
            <div className="testimonial-image-and-name">
              <div className="testimonial-image">
                <img src="https://assets.website-files.com/61e3aa555a9149f4f1e68fe4/61e3aa555a91492d63e690e4_Avatar-5.png" loading="lazy" alt="" className="image">
                </img>
              </div>
              <div className="testimonial-name-and-designation">
                <h5>Steve McQuillen</h5>
                <div className="_16-px">Founder, TechMatter
                </div>
              </div>
            </div>
            <div>
              <p>“With shelter-in-place, Patreon has become a great source of income for 140,000 creators. But as we’ve scaled, with every new release we spent up to 4 days implementing or fixing analytics.”

              </p>
            </div>
          </div>
        </div>
        <div className="registration__formContainer sign-up-right">
          <h6 class="subheading">ACCOUNT</h6>
          <h2 className="sign-up-heading">
            Log in to continue
          </h2>
          <div onClick={() => setRedirectToSignUp(true)} className="linkToRedirect" >
            Don’t have an account?
            <span class="sign-up-link"> Sign Up</span>
          </div>
          <form onSubmit={onSubmit} className="password-form sign-in-form">
            <InputField
              label="Your email"
              placeholder="tomfrank@example.com"
              value={email}
              name="email"
              onChange={onChange}
              type="email"
              disabled={loggingInState}
              className="contact-form-text-field"
            />
            <InputField
              label="Your password"
              placeholder="Better be a good one"
              value={password}
              name="password"
              onChange={onChange}
              type="password"
              disabled={loggingInState}
              className="contact-form-text-field"
            />

            <Button
              buttonText="Log In"
              loggingInState={loggingInState} />
            <a href="/forgot" className="forgot-password">Forgot password? </a>
          </form>

        </div>
      </div>
    </div>
  );
};

export default SignIn;