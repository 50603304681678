import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import search from "../assets/search.png";
import plus from "../assets/plus.png";
import bell from "../assets/bell.png";
import notification from "../assets/notification.png";
import profile from "../assets/34.png";
import { CHANGE_SELECTED_STORES } from "../redux/selectedStore/actions";
import { UserContext } from "./Page";
const TopBar = ({ stores, dispatch, selectedStore }) => {
  const [redirectToForm, setRedirectToForm] = useState(false);
  const user = useContext(UserContext);
  console.log("selected stores : " + selectedStore);
  console.log("stores : " + stores);
  const redirectMeToForm = () => {
    if (redirectToForm) {
      return <Redirect to="/home/form" />;
    }
  };
  const changeHandler = (e) => {
    switch (e.target.name) {
      case "stores":
        return dispatch(CHANGE_SELECTED_STORES(e.target.value));

      default:
        break;
    }
  };
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    const sideBarElement = document.getElementsByClassName("sideBar")[0];
    sideBarElement.style.left = showSidebar ? "0px" : "-253px";
  };
  useEffect(() => {
    // listen for window resize
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1290) {
        setShowSidebar(true);
        const sideBarElement = document.getElementsByClassName("sideBar")[0];
        sideBarElement.style.left = "-253px";
      } else {
        setShowSidebar(true);
        const sideBarElement = document.getElementsByClassName("sideBar")[0];
        sideBarElement.style.left = "0px";
      }
    });
  }, []);
  return (
    <div className="topBar">
      <button className="side-bar-toggle" onClick={toggleSidebar}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 16H27"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 8H27"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 24H27"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {redirectMeToForm()}
      <div className="topBar__leftContainer">
        <div className="topBar__leftContainer__dropDownSection">
          <div className="topBar__leftContainer__dropDownSection--icon">
            <img src={search} alt="search icon" />
          </div>
          <div className="topBar__leftContainer__dropDownSection--dropdown">
            <select
              name="stores"
              id="cars"
              onChange={changeHandler}
              placeholder="--Select Store--"
              value={selectedStore}
            >
              {/* Stores coming as prop from parent */}
              {!selectedStore && <option value="">--Select Store--</option>}
              {Object.values(stores).map((store) => (
                <option value={store.propertyid} key={store.propertyid}>
                  {store.propertyname}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Link className="topBar__leftContainer__plusButton" to="/home/form">
          <img src={plus} alt="add more store button" />
        </Link>
      </div>
      <div className="topBar__rightContainer">
        <div className="topBar__rightContainer__item">
          <img src={notification} alt="notification icon" />
        </div>
        <div className="topBar__rightContainer__item">
          <img src={bell} alt="bell icon" />
        </div>
        <div className="topBar__rightContainer__D">
          <div className="topBar__rightContainer__dropDownSection">
            <div className="topBar__rightContainer__dropDownSection--icon">
              <img src={profile} alt="profile pic" />
            </div>
            <div className="topBar__rightContainer__dropDownSection--dropdown">
              <select name="cars" id="cars">
                <option value="volvo">
                  {user?.firstname} {user?.lastname}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;