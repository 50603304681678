import { ChangeStores, AppendStores, AddReviewLinks } from "./types";

const initialStores = {};

const storesReducer = (state = initialStores, action) => {
  switch (action.type) {
    case ChangeStores:
      const newStore = Object.fromEntries(
        action.payload.map(({ propertyid, propertyname, propertytype }) => [propertyid, { propertyid, propertyname, propertytype }])
      );
      return newStore;
      case AppendStores:
        return {...state,
          [action.payload.propertyid] : action.payload
        };
      case AddReviewLinks:
        let propertyValue = state[action.payload.propertyid];
        if(!propertyValue["reviewLinks"]){
          propertyValue.reviewLinks = action.payload;
        } else {
          propertyValue.reviewLinks.push(action.payload);  
        }

        return {...state,
          [action.payload.propertyid] : propertyValue
        };

    default:
      return state;
  }
};

export default storesReducer;
