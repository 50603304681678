import "./Spinner.scss";
import React, { Component } from 'react'
import  {ReactComponent as PageSpinner } from "../../../assets/svg/pageLoadingSpinner.svg";

export class Spinner extends Component {
  render() {
    return (
      <div>
        <div className="spinner-wrapper">
            <PageSpinner/>
        </div>
      </div>
    )
  }
}

export default Spinner
