import {STORAGE_KEY } from "../helpers/variables";

  const getLocalAccessToken = () => {      
    const user = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return user?.authToken;
  };

  const getRefreshToken = () => {      
    const user = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return user?.refreshtoken;
  };

  const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem(STORAGE_KEY));
    user.authToken = token;    
    localStorage.setItem(STORAGE_KEY, JSON.stringify(user));
  };

  const getUser = () => {
    return JSON.parse(localStorage.getItem(STORAGE_KEY));
  };

  const setUser = (user) => {
    console.log(JSON.stringify(user));
    localStorage.setItem(STORAGE_KEY, JSON.stringify(user));
  };

  const removeUser = () => {
    localStorage.removeItem(STORAGE_KEY);
  };
  
  const TokenService = {
    getLocalAccessToken,
    getRefreshToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
  };
  export default TokenService;