import React, { useEffect, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORAGE_KEY } from "../helpers/variables";
import { CHANGE_STORES } from "../redux/stores/actions";
import { CHANGE_SELECTED_STORES } from "../redux/selectedStore/actions";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import UserService from "../services/user.service";

const Page = ({ children }) => {
  //Getting state from redux
  const globalState = useSelector((state) => state);
  const { stores, selectedStore } = globalState;
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();

  const getStores = () => {
    const user = JSON.parse(localStorage.getItem(STORAGE_KEY));
    setUserData(user);

    if(!stores || Object.keys(stores).length === 0){
      UserService.getPropertyNames().then((res) => {
        console.log("data properties: " + res.data.properties);
        //saving it in global state to access anywhere in app.
        dispatch(CHANGE_STORES(res.data.properties));
  
        if (!selectedStore && res.data.properties?.length) {
          dispatch(
            CHANGE_SELECTED_STORES(
              res.data.properties && res.data.properties[0].propertyid
            )
          );
        }
      });
    }
  };

  useEffect(() => {
    // gets all the stores that are available to select from backend
    getStores();
  }, []);

  return (
    <UserContext.Provider value={userData}>
      <div className="page">
        {/* Side bar component */}
        <SideBar dispatch={dispatch} />
        {/* Top bar takes these props and on basis of these props lists Stores in dropdown and gives us option to select the store from further usage */}
        <TopBar
          // Stores are list of stores coming from redux
          stores={stores}
          // selected Store is being sent to select a store when we click on it.
          selectedStore={selectedStore}
          // Dispatch is used to set redux state
          dispatch={dispatch}
        />
        {/* Children prop helps to destructure or list the component that it is provided inside. i.e. */}
        {/* <Page> 
            <SomeComponent />
          </Page>
      */}
        {/* SomeComponent is children here. */}
        <div className="subScreen" id="subScreen">
          {children}
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default Page;
export const UserContext = createContext();