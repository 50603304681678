import { STORAGE_KEY } from "../helpers/variables";
import api from "./api";
import TokenService from "./token.service";
import store from "../redux/index";
import { CLEAR_STORES } from "../redux/stores/actions";
import { CLEAR_SELECTED_STORES } from "../redux/selectedStore/actions";

const register = (email, password, first_name, last_name) => {
  return api
    .post("/users/register", {
      email,
      password,
      first_name,
      last_name,
    })
    .then((response) => {
      if (response.data?.user?.authToken) {
        TokenService.setUser(response.data.user);
      }
      return response.data.user;
    });
};

const login = (email, password) => {
  return api
    .post("/users/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data?.user?.authToken) {
        TokenService.setUser(response.data.user);
      }
      return response.data.user;
    });
};

const logout = () => {
  TokenService.removeUser();
  const { dispatch } = store;
  dispatch(CLEAR_STORES());
  dispatch(CLEAR_SELECTED_STORES());
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem(STORAGE_KEY));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default AuthService;
