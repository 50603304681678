import React, { useState, useContext, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import Logo from "../assets/Logo.png";
import Logout from "../assets/logout.png";
import { sideNavigation } from "../helpers/navigationOptions";
import { UserContext } from "./Page";
import AuthService from "../services/auth.service";
import { signInPath } from "../helpers/path";
import { redirectToPath } from "../helpers/helpersFunctions";

const SideBar = ({ dispatch }) => {
  const [state, setState] = useState({});
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);

  const user = useContext(UserContext);

  const isPathActive = (path) => {
    return window.location.pathname.startsWith(path);
  };

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  const dropdownPaths = [{ path: "/settings", state: "settingMenuOpen" }];

  dropdownPaths.forEach((obj) => {
    if (isPathActive(obj.path)) {
      setState({ [obj.state]: true });
    }
  });

  //Logout function.
  const logoutFunction = async () => {
    AuthService.logout();
    setRedirectToSignIn(true);
  };

  const toggleSidebar = () => {
    const sideBarElement = document.getElementsByClassName("sideBar")[0];
    // toggle class to shrink
    sideBarElement.classList.toggle("shrink");
    document
      .getElementsByClassName("side-bar-large-toggle")[0]
      .classList.toggle("shrink");
    document.getElementsByClassName("topBar")[0].classList.toggle("expand");
    document.getElementsByClassName("subScreen")[0].classList.toggle("expand");
  };

  useEffect(() => {
    /// listen for window resize
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1291) {
        const sideBarElement = document.getElementsByClassName("sideBar")[0];
        sideBarElement.classList.remove("shrink");
        document
          .getElementsByClassName("side-bar-large-toggle")[0]
          .classList.remove("shrink");
        document
          .getElementsByClassName("topBar")[0]
          .classList.remove("expand");
        document
          .getElementsByClassName("subScreen")[0]
          .classList.remove("expand");
      }
    });
  }, []);

  return (
    <nav className="sideBar">
      <button className="side-bar-large-toggle" onClick={toggleSidebar}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 26L15 16L25 6"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 26L5 16L15 6"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {redirectToPath(redirectToSignIn, signInPath)}
      <div className="sideBar__logo">
        <div className="sideBar__logo__img">
          <img src={Logo} alt="Logo" />
        </div>

        <h1>REP BRIDGE</h1>
      </div>
      {/* Iterates over array of predefined components and paths. you can view it in src/helpers/navigation  or by holding control and clicking the varibale */}
      {sideNavigation.map((item, key) => (
        <div key={key}>
          {item.subMenu ? (
            <li>
              <div
                className={`sideBar__item${
                  state.settingMenuOpen ? "" : " menu-expanded"
                }${isPathActive(item.path) ? " active" : ""}`}
                onClick={() => toggleMenuState("settingMenuOpen")}
                data-toggle="collapse"
              >
                <div className="sideBar__item--icon">
                  <img src={item.logo} alt={`${item.title} icon`} />
                </div>
                <span className="sideBar__item--name">{item.title}</span>
              </div>
              <Collapse in={state.settingMenuOpen}>
                <div>
                  <ul className="nav flex-column sub-menu">
                    {item.subMenu.map((subitem, key) => (
                      <li
                        className="sideBar__item"
                        style={{ paddingLeft: "25%" }}
                        key={`s${key}`}
                      >
                        <Link
                          className={
                            isPathActive(subitem.path)
                              ? "sideBar__item--link active"
                              : "sideBar__item--link"
                          }
                          to={subitem.path}
                        >
                          <div className="sideBar__item--icon">
                            <img
                              src={subitem.logo}
                              alt={`${subitem.title} icon`}
                            />
                          </div>
                          <span className="sideBar__item--name">
                            {subitem.title}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Collapse>
            </li>
          ) : (
            <li>
              <Link
                className={
                  isPathActive(item.path)
                    ? "sideBar__item active"
                    : "sideBar__item"
                }
                to={item.path}
              >
                <div className="sideBar__item--icon">
                  <img src={item.logo} alt={`${item.title} icon`} />
                </div>
                <span className="sideBar__item--name">{item.title}</span>
              </Link>
            </li>
          )}
        </div>
      ))}

      {user?.plan === "trial" && (
        <div className="sideBar__PlanButton">
          <button className="sideBar__PlanButton__button">
            View Pricing Plan
          </button>
          <span>Trial Expires in {user.trial_expires} days</span>
        </div>
      )}

      <div className="sideBar__LogoutButton" onClick={logoutFunction}>
        <button className="sideBar__LogoutButton__button">
          <img src={Logout} alt="Logout icon" />
          LOGOUT
        </button>
      </div>
    </nav>
  );
};

export default withRouter(SideBar);