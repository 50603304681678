import { ChangeSelectedStore } from "./types";

export const CHANGE_SELECTED_STORES = (data) => {
  return {
    type: ChangeSelectedStore,
    payload: data,
  };
};

export const CLEAR_SELECTED_STORES = (data) => {
  return {
    type: ChangeSelectedStore,
    payload: "",
  };
};